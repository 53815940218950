
  .base-timer {
    position: fixed;
    width: 4rem;
    height: 4rem;
    bottom: 1vh;
    right: 1vh;
    z-index: 10;
  }
  
  .base-timer__svg {
    transform: scaleX(-1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 10px;
    stroke: grey;
  }
  
  .base-timer__path-remaining {
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: #F79327;
  }
  
  .base-timer__path-remaining.orange {
    color: #FC4E1C;
  }
  
  .base-timer__path-remaining.red {
    color: #800000;
  }
  
  .base-timer__label {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #F79327;
  }

  
@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');


.text-alert {
    font-family: 'Quicksand';
    font-weight: bold;
    color: #fff;
    margin: auto;
}

.alert {
    width: 35%;
    margin: 0 auto;
    padding: 15px;
    position: fixed;
    bottom: 1vh;
    right: 12vh;
    z-index: 10;
    border-radius: 32px;
    background-color: #F79327;
    border-left: 10px solid #cf6b00;
    display: flex;
    align-items: center;
    justify-content: center;
}

  @media screen and (max-width: 768px) {
    .base-timer {
        width: 4rem;
        height: 4rem;
    }

    .base-timer__label {
        position: absolute;
        width: 4rem;
        height: 4rem;
        font-size: 16px;
    }

    .text-alert  {
        font-size: 0.9rem;
    }

    .alert {
        width: 70%;
    }
  }