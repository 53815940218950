.container-tabs {
  /* background-color: #c8c8c8; */
  /* background-color: #dfdede; */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: left;
}
.selected {
    /* width: 20rem; */
    text-transform: uppercase;
    background-color: #dfdede;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-size: 0.875rem;
    border: none;
    cursor: pointer;
    box-shadow: 0px -2px 3px rgb(0 0 0 / 10%);
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .border-left {
    border-top-left-radius: 32px;
  }

  .border-rigth {
    border-top-left-radius: 32px;
  }
  
  
  .edit {
    background-color: #f1f1f1;
    color: black;
    outline: none;
    transition: .40s ease;
    font-weight: bolder;
    
  }

  @media screen and (max-width: 767px) {
    .container-tabs {
      width: 100%;
    }
}