:root {
  /* --primary: #F79327; */
  --primary: #F7931A;
  --dark: #464646;
  --green: #36CC72;
  --black: #000000;
  --textlight: #ffffff;
  --light: #f7f6fb;
  --lightbg: #F1F1F1;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.principal-container {
  margin-top: 2rem;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: var(--primary);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.linea-separadora {
  border-bottom: 1px solid var(--light);
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: large;
  font-weight: bold;
}

.linea-separadora-dark {
  border-bottom: 1px solid var(--dark);
  font-family: 'Roboto', sans-serif;
  font-size: large;
  font-weight: bold;;
}

.btn-canjear {
  background-color: var(--black);
  border: none;
  color: var(--textlight);
  padding: 0.8rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 32px;
  box-shadow: 0px 6px 10px #00000029;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.btn-canjear:hover {
  background-color: var(--primary);
}

.btn-realizado-finalizar {
  width: 15rem;
  margin-top: 0;
  float: left;
}

.btn-realizado-canjear {
  width: 15rem;
  margin-top: 0;
  float: right;
}

.img-bitcoin-title-home {
  max-height: 2.5rem;
  align-items: center;
}

.img-bitcoin-home {
  max-height: 20rem;
}

.img-tarjeta-home {
  max-height: 22rem;

}

.img-tarjeta-instructions {
  max-height: 22rem;

}

.img-bitcoin-opacity-home {
  max-height: 18rem;
}

.col-img-bitcoin-opacity-home {
  text-align: left;
}

.text-h1-light {
  font-weight: bold;
  color: var(--textlight);
}

.bold {
  font-weight: bold;
}

.card-text-right {
  text-align: right;
}

.card-text-left {
  text-align: left;
}

.card-text-wallet-onchain {
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.div-cuadro-numero {
  background-color: var(--light);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  font-weight: bold;
  font-size: 1.5rem;
  height: 3rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.div-rectangulo-naranja {
  background-color: var(--primary);
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  font-size: 1.5rem;
  height: 7rem;
  width: 90%;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
  color: var(--textlight);
}

.div-rectangulo-naranja ul {
  margin-bottom: 0;
  padding-left: 2rem;
}

.img-bitcoinsign-home {
  max-height: 28rem;
  align-items: center;
  margin-top: -12rem;
}

.col-img-bitcoinsign-instrucciones {
  margin-top: -4rem;
  margin-left: -14rem;
  z-index: 0;
  text-align: left;
}

.img-bitcoinsign-instrucciones {
    max-height: 24rem;
    align-items: center;
    opacity: 0.7 !important;
}

.img-bitcoin-transaccion {
    max-height: 28rem;
    align-items: center;
    opacity: 75%;
}

.img-card-transaction {
  max-height: 6rem;
}

.img-opacity-home {
  margin-top: -4rem;
  max-height: 16rem;
}

.img-wallet-home {
  max-height: 6rem;
  box-shadow: 0px 6px 10px #00000029;
  border-radius: 100%;
}

.centrar-items {
  display: flex;
  align-items: center;
}

.div-bg-light {
  background-color: var(--lightbg);
  padding-right: 0rem;
  padding-left: 0rem;
}

.div-bg-dark {
  background-color: var(--black);
  padding-right: 0rem;
  padding-left: 0rem;
}

.card-form {
  background-color: var(--lightbg);
  border-radius: 8px;
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.card-transaction {
  width: 70%;
  margin: auto;
  background-color: var(--lightbg);
  border-radius: 40px;
  padding: 1rem;
}

.card-shadow {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
}

.field-form {
  border-radius: 32px;
  border: 1px solid var(--dark);
  width: 90%;
  height: 2.5rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.field-wallet {
  border-radius: 40px;
  border: 0.1px solid var(--dark);
  font-size: 1rem;
  height: 4.5rem;
  text-align: center;
  width: 100%;
}

.field-email {
  border-radius: 40px;
  border: 0.1px solid var(--dark);
  font-size: 1rem;
  height: 3.5rem;
  text-align: center;
  width: 70%;
}

.label-form {
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 2.5rem;
}

.p-form {
  text-align: left;
  font-size: 1.2rem;
  margin-left: 2.5rem;
  color: var(--light);
}

.p-wallet {
  font-size: 0.8rem;
}

.acordion-card {
  margin: auto;
  margin-bottom: 2rem;
  width: 70%;
}

.box {
  margin: 0;
  height: 100px;
  width: 100%;
  overflow-wrap: break-word;
}

.ln-box {
  height: auto;
  overflow-wrap: break-word;
}

p#lnbctext {
  width: 18em;
  margin: 5px;
  padding: 10px;
  text-align: justify;
  text-align-last: justify;
}

p#txid {
  width: 25em;
  margin: 0 auto;
  padding: 0;
}

/* Medias queries */
@media screen and (max-width: 767px) {

  .img-tarjeta-home {
    max-height: 12rem;
  }

  .img-bitcoin-title-home {
    max-height: 2rem;
  }

  .img-bitcoinsign-home {
    max-height: 16rem;
    margin-top: -8.5rem;
    align-items: center;
  }
  .div-rectangulo-naranja {
    border-radius: 32px;
    font-size: 1.2rem;
    width: 100%;
    height: 6rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .div-rectangulo-naranja ul {
    margin-bottom: 0;
    padding-left: 1rem;
  }

  .img-tarjeta-instructions {
    max-height: 12rem;
  }

  .col-img-bitcoinsign-instrucciones {
    margin-top: -1rem;
    margin-left: -5rem;
    z-index: 0;
    text-align: left;
  }

  .img-bitcoinsign-instrucciones {
    max-height: 6rem;
    margin-left: -1rem;
  }

  .img-bitcoin-transaccion {
    max-height: 8rem;
  }

  .card-transaction {
    width: 100%;
  }

  .acordion-card {
    width: 100%;
  }

  .field-wallet {
    font-size: 0.7rem;
  }

  .h2-soporte {
    font-size: 1.2rem;
  }

  .btn-realizado-finalizar, .btn-realizado-canjear {
    float: none;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 299px) {
  .recaptcha {
    display: table;
    margin: 0 auto;
    transform: scale(0.60);
  }

  .h2-soporte {
    font-size: 0.9rem;
  }

  .h5-qr-text {
    font-size: 1rem;
  }

  .img-bitcoinsign-instrucciones {
    margin-left: -1rem;
  }

  .h5-email-realizado {
    font-size: 0.9rem;
  }

  .img-opacity-home {
    display: table;
    margin: auto;
    margin-top: 0rem;
    height: auto;
    width: 100%;
  }

  .card-text-right {
    font-size: 0.8rem;
  }

  .card-text-left {
    font-size: 0.8rem;
  }

  .card-text-wallet-onchain {
    text-align: center;
    margin-left: 0rem;
    margin-right: 0rem;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 349px) {
  .p-wallet {
    font-size: 0.7rem;
    overflow-x: scroll;
  }

  .recaptcha {
    transform: scale(0.80);
  }

  .div-rectangulo-naranja {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .h2-soporte {
    font-size: 1rem;
  }

  .h5-qr-text {
    font-size: 1rem;
  }

  .h5-email-realizado {
    font-size: 0.9rem;
  }

  .img-opacity-home {
    display: table;
    margin: auto;
    margin-top: 0rem;
    max-height: 10rem;
  }

  .card-text-right {
    font-size: 0.8rem;
  }

  .card-text-left {
    font-size: 0.8rem;
  }

  .card-text-wallet-onchain {
    text-align: center;
    margin-left: 0rem;
    margin-right: 0rem;
    font-size: 0.9rem;
  }

}

@media screen and (min-width: 350px) and (max-width: 463px) {
  .h2-soporte {
    font-size: 0.9rem;
  }

  .h5-email-realizado {
    font-size: 0.9rem;
  }

  .img-opacity-home {
    display: table;
    margin: auto;
    margin-top: 0rem;
    max-height: 12rem;
  }

  .card-text-right {
    font-size: 0.9rem;
  }

  .card-text-left {
    font-size: 0.9rem;
  }

  .card-text-wallet-onchain {
    text-align: center;
    margin-left: 0rem;
    margin-right: 0rem;
    font-size: 1rem;
  }
}

@media screen and (min-width: 464px) and (max-width: 636px) {

  .img-bitcoinsign-instrucciones {
    max-height: 8rem;
    margin-left: -2rem;
  }

  .img-opacity-home {
    margin-top: 0rem;
    max-height: 12rem;
  }

  
}
@media screen and (min-width: 636px) and (max-width: 768px) {

  .img-bitcoinsign-instrucciones {
    max-height: 11rem;
    margin-left: -4rem;
  }

  .img-opacity-home {
    margin-top: 0rem;
    max-height: 12rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .img-bitcoinsign-instrucciones {
    max-height: 18rem;
    margin-left: 3rem;
  }

  .img-bitcoin-transaccion {
    max-height: 18rem;
  }

  .img-bitcoinsign-home {
    max-height: 10rem;
    margin-top: -6rem;
    align-items: center;
  }

  .h2-soporte {
    font-size: 1.5rem;
  }

  .img-opacity-home {
    margin-top: 0rem;
    max-height: 12rem;
  }
}

@media screen and (min-width: 1024px)  and (max-width: 1280px) {
  
  .img-bitcoinsign-home {
    max-height: 18rem;
    margin-top: -8rem;
    align-items: center;
  }

  .img-bitcoin-transaccion {
    max-height: 24rem;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {

  .img-bitcoinsign-home {
    max-height: 20rem;
    margin-top: -10rem;
  }
  
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  .img-bitcoinsign-home {
    max-height: 26rem;
  }
  
}


@media  screen and ( min-width: 1920px) {

  .img-tarjeta-home {
    max-height: 28rem;
  }
  
  .img-bitcoinsign-instrucciones {
    margin-left: -8rem;
    max-height: 30rem;
    margin-top: -2rem;
  }

  .card-form {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .p-form {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}


@media screen and (min-width: 2560px) {
  .img-bitcoinsign-instrucciones {
    margin-left: -16rem;
    max-height: 30rem;
    margin-top: -2rem;
  }

  .card-form {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .p-form {
    margin-left: 16rem;
    margin-right: 16rem;
  }
}


@media screen and (min-width: 3840px) {
  .img-bitcoinsign-instrucciones {
    margin-left: -44rem;
    max-height: 30rem;
    margin-top: -2rem;
  }

  .card-form {
    margin-left: 32rem;
    margin-right: 32rem;
  }

  .p-form {
    margin-left: 32rem;
    margin-right: 32rem;
  }
}

@media screen and (min-width: 5000px) {
  .img-bitcoinsign-instrucciones {
    margin-left: -70rem;
    max-height: 30rem;
    margin-top: -2rem;
  }

  .card-form {
    margin-left: 40rem;
    margin-right: 40rem;
  }

  .p-form {
    margin-left: 40rem;
    margin-right: 40rem;
  }
}


@media screen and (min-width: 7000px) {
  .img-bitcoinsign-instrucciones {
    margin-left: -120rem;
    max-height: 30rem;
    margin-top: -2rem;
  }

  .card-form {
    margin-left: 64rem;
    margin-right: 64rem;
  }

  .p-form {
    margin-left: 64rem;
    margin-right: 64rem;
  }
}


/* medias container */

@media (min-width: 1400px) and (max-width: 1500px) {
  .container {
    max-width: 1140px;
  }
}