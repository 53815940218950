.body-bg {
    background-image: url("../assets/backgroundv8.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

.h4-home {
    color: #FFFFFF;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.img-stores {
    width: 120px;
    transition: transform 0.5s ease;
}

.img-stores:hover {
  transform: translateY(-0.3rem);
} 

/* Media queries */

/* movil */



@media screen and (max-width: 347px) {

    .h4-home {
        margin-left: 0rem;
        margin-right: 0rem;
        font-size: 0.9rem;
    }

    .body-bg  {
        background-position: 90% -4rem;
        background-size: auto 50%; 
    }
} 

@media screen and (min-width: 348px) and (max-width: 359px) {

    .h4-home {
        margin-left: 0rem;
        margin-right: 0rem;
        font-size: 0.9rem;
    }

    .body-bg  {
        background-position: 90% -2rem;
        background-size: auto 50%; 
    }
} 
    
@media screen and (min-width: 360px) and (max-width: 399px) {

    .body-bg  {
        background-position: 90% -4rem;
        background-size: auto 52%; 
    }

    .h4-home {
        margin-left: 0rem;
        margin-right: 0rem;
        font-size: 1rem;
    }
}

@media screen and (min-width: 400px) and (max-width: 575px) {
    .body-bg  {
        background-position: 90% -7rem;
        background-size: auto 55%; 
    }

    .h4-home {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {

    .body-bg  {
        background-position: 90% -9rem;
        background-size: auto 74%; 
    }

    .h4-home {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .body-bg  {
        background-position: 90% -7rem;
        background-size: auto 72%; 
    }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {

    .body-bg  {
        background-position: 90% -10rem;
        background-size: auto 80%;    
    }
}

/* laptop */


@media screen and (min-width: 1025px) and (max-width: 1199px) {
    .body-bg  {
        background-position: 90% -14rem;
        background-size: auto 82%; 
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .body-bg  {
        background-position: 90% -14rem;
        background-size: auto 85%; 
    }
}

@media screen and (min-width: 1441px) and (max-width: 1880px) {
    .body-bg  {
        background-position: 90% -18rem;
        background-size: auto 90%;  
    }
}

/* desktop */

@media screen and (min-width: 1881px) and (max-width: 1920px) {
    .body-bg  {
        background-position: 90% -16rem;
        background-size: auto 90%;  
    }
}

@media screen and (min-width: 1921px) and (max-width: 2150px) {

    .body-bg  {
        background-position: 100% -20rem;
        background-size: auto 92%; 
    }
}

@media screen and (min-width: 2151px) and (max-width: 2299px) {

    .body-bg  {
        background-position: 100% -20rem;
        background-size: auto 93%;   
    }
}


@media screen and (min-width: 2300px){

    .body-bg  {
        background-position: 100% -20rem;
        background-size: auto 95%; 
    }
}

@media screen and (min-width: 2500px){

    .body-bg  {
        background-position: 100% -22rem;
        background-size: auto 100%;
    }
}

@media screen and (min-width: 2720px) {

    .body-bg  {
        background-position: 0% -22rem;
        background-size: cover; 
    }
}

@media screen and (min-width: 3000px) {
    .body-bg  {
        height: 100%;
        background: linear-gradient(186deg, #FFFFFF 22%, #000000 22.2%, #000000 57%, #FFFFFF 57.2%);
    }

    /* .body-bg  {
        background-position: 0% -36rem;
        background-size: cover; 
    } */
}
