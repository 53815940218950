.img-selector {
    max-width: 12% !important;
}

.img-selector-wallets {
    max-width: 50% !important;
}

.btnCentered {
    margin: 0 ;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/** Media queries**/
@media (max-width: 576px) {
    .img-selector-wallets {
        max-width: 30% !important;
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .img-selector-wallets {
        max-width: 30% !important;
        margin-top: 1rem;
    }
}